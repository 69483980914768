import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { DISCOUNT_CUSTOM_LIST_PRODUCT_BUNDLES, PRODUCT_BUNDLE_CUSTOM_LIST_PARENT, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOM_PRICE, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_PACKAGE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_BUNDLE_ITEM } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';


const DiscountsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: DISCOUNT_CUSTOM_LIST_PRODUCT_BUNDLES,
                    params: {
                        bundleId: item.id,
                    }
                },
                embedded: true,
                hideCreateButton: true,
                title: "Discounts",
                subTitle: "Product discounts",
                objectType: OBJECT_TYPE_DISCOUNT,
                pagePath: "/discounts/",
                history: history
            })}
        </div>
    )
}


// const ProductsList = ({item}) => {
//     const history = useHistory();
//     return (
//         <div style={{marginTop: '1rem'}}>
//             {TableTemplate.renderTemplate({
//                 tableRelationMode: {
//                     tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

//                     parentId: item.id,
//                     parentType: OBJECT_TYPE_PRODUCT_BUNDLE,

//                     childType: OBJECT_TYPE_PRODUCT,
//                     manyToManyChildView: true,

//                     showNotIncluded: false
//                 },
//                 embedded: true,
//                 title: "Products", 
//                 subTitle: "Products in bundle", 
//                 objectType: OBJECT_TYPE_PRODUCT, 
//                 pagePath: "/products/", 
//                 history: history
//             })}
//         </div>
//     )
// }


const ItemsList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentBundleId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Items",
                subTitle: "Product bundle items",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE_ITEM,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_PRODUCT_BUNDLE_ITEM}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                productFinderShowReplaceWithBundle={true}
                productFinderShowReplaceWithProductGroup
                open={showCreateDialog || selectedItem} onClose={e => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentBundleId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

const ProductBundlesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: PRODUCT_BUNDLE_CUSTOM_LIST_PARENT,
                    params: {
                        parentId: item.id,
                    }
                },
                customToolbarContent: (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', flex: 1 }}>
                        <p>Bundles containing this bundle</p>
                    </div>
                ),
                hideCreateButton: true,
                embedded: true,
                title: "Product Bundles",
                subTitle: "Bundles containing this bundle",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/product-bundles/",
                history: history
            })}
        </div>
    )
}


const CustomPriceList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Price Rules",
                subTitle: "Product bundle price rules",
                objectType: OBJECT_TYPE_CUSTOM_PRICE,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_CUSTOM_PRICE}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}

export const PackageUnitsList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Package Units",
                subTitle: "Product package units",
                objectType: OBJECT_TYPE_PACKAGE_UNIT,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_PACKAGE_UNIT}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}


class ProductBundleDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_PRODUCT_BUNDLE, listener)
            } else {
                Api.getItem(OBJECT_TYPE_PRODUCT_BUNDLE, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                    itemResult: this.state.itemResult,
                    pagePath: "/product-bundles/",
                    history: this.props.history,
                    returnItemOnSave: true,
                    replaceItemOnSave: true,
                    customTabs: [
                        {
                            title: "Package Units",
                            component: PackageUnitsList
                        },
                        {
                            title: "Items",
                            component: ItemsList
                        },
                        {
                            title: "Price Rule",
                            component: CustomPriceList
                        },
                        {
                            title: "Discount",
                            component: DiscountsList
                        },
                        {
                            title: "Bundles",
                            component: ProductBundlesList
                        }
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ProductBundleDetailPage);