import React from 'react'
import Page from '../../../base/Page';
import Button from '../../../components/Button';
import Api from '../../../session/Api';


import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
    DataVis_232,
    Store32,
    Cube32,
    DeliveryParcel24,
    DeliveryParcel16
} from '@carbon/icons-react'
import { ComboBox, DatePicker, DatePickerInput, Link, RadioTile, TileGroup } from 'carbon-components-react';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar
} from 'carbon-components-react';

import Util, { DAY_MS, handleEndDate } from '../../../util/Util';
import UIUtil from '../../../util/UIUtil';
import { DESTINATION_TYPE_MOBILE_UNIT, DESTINATION_TYPE_STORE, DESTINATION_TYPE_WAREHOUSE } from '../../../constants/Constants';
import ProfilePic from '../../../components/ProfilePic';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_STORE, OBJECT_TYPE_WAREHOUSE } from '../../../constants/ObjectTypes';

import { subDays, addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
// import DateRangePicker from '../../../components/date/DateRangePicker'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Link as LR } from 'react-router-dom'
import PaginatedDataTable from '../../../components/data-table/PaginatedDataTable';
import useHover from '../../../hooks/useHover';

const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        <p style={{ marginTop: '0.5rem', fontSize: 12, opacity: 0.65 }}>
            {desc}
        </p>
    </div>
)

/*
rivate String name;
    private String sku;
    private String supplier;
    private String brand;
    private String department;
    private String category;
    private String subcategory;

    private BigDecimal quantitySold;
    private BigDecimal totalRevenue;
    private BigDecimal cogs;
    private BigDecimal totalProfit;
    private BigDecimal grossProfitMargin;
    */

const HEADERS = [
    {
        header: "Product",
        key: "name"
    },
    {
        header: "Ref No",
        key: "sku"
    },
    {
        header: "Supplier",
        key: "supplier"
    },
    {
        header: "Brand",
        key: "brand"
    },
    {
        header: "Department",
        key: "department"
    },
    {
        header: "Category",
        key: "category"
    },
    {
        header: "Subcategory",
        key: "subcategory"
    },
    {
        header: "Qty",
        key: "quantitySold"
    },
    {
        header: "Revenue",
        key: "totalRevenue"
    },
    {
        header: "COGS",
        key: "cogs"
    },
    {
        header: "Profit",
        key: "totalProfit"
    },
    {
        header: "Gross Profit Margin",
        key: "grossProfitMargin"
    },
]


const FilterIndicator = ({ name, value, combobox }) => {
    const hover = useHover();
    return (
        <div {...hover.init()} style={{ flex: 1 }}>
            <h6 style={{ opacity: value ? 1 : 0.65, }}>{name}</h6>
            {hover.isHover ? (
                <div style={{ marginTop: '0.25rem', animation: 'opacity-fade-in-anim 250ms' }}>
                    {/* <ComboBox size="sm" light style={{}} /> */}
                    {combobox}
                </div>
            ) : <p style={{ fontSize: 12, opacity: value ? 1 : 0.65, }}>{value ? value.value : 'Any'}</p>}
        </div>
    )
}

class ProductSalesReport extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingReport: false,
            generatedReport: false,

            report: undefined,

            dateRange: [{
                startDate: subDays(new Date(), 1),
                endDate: new Date(),
                key: 'selection'
            }],

            brands: [],
            categories: [],
            subcategories: [],
            departments: [],
            suppliers: [],
        }
    }

    clearReport() {
        this.setState({
            generatingReport: false,
            generatedReport: false,

            // dateRange: [{
            //     startDate: subDays(new Date(), 1),
            //     endDate: new Date(),
            //     key: 'selection'
            // }],
        })
    }

    canGenerateReport() {
        //return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
        return this.state.dateRange.length > 0;
    }

    sendReportRequest(page, sortRequest, listener) {
        Api.getProductSalesReport({
            startDate: this.state.dateRange[0].startDate.getTime(),
            endDate: handleEndDate(this.state.dateRange[0].endDate.getTime()),

            brandId: this.state.brandId,
            categoryId: this.state.categoryId,
            subcategoryId: this.state.subcategoryId,
            departmentId: this.state.departmentId,
            supplierId: this.state.supplierId,

            paginationRequest: { page, sortRequest }
        }, listener);
    }

    generateReportBtn() {
        this.setState({ generatedReport: true })
        // this.setState({generatingReport: true})
        // Api.getProductSalesReport({

        // }, response => P)
        // Api.getProductPerformance(this.state.dateRange[0].startDate.getTime(), this.state.dateRange[0].endDate.getTime() + DAY_MS, false, response => {
        //     if (response.status === true) {
        //         this.setState({generatingReport: false, report: response.payload, generatedReport: true,})
        //         UIUtil.showSuccess();
        //     } else {
        //         this.setState({generatingReport: false, generatedReport: false})
        //         UIUtil.showError(response.message);
        //     }
        // })
    }

    onPageStart() {
        this.callPageApi(listener => Api.getProductSalesReportEndpointsList(listener), payload => ({
            brands: payload.brands,
            categories: payload.categories,
            subcategories: payload.subcategories,
            departments: payload.departments,
            suppliers: payload.suppliers,
        }))
    }

    renderComboBox(title, list, property, small) {
        return (<>
            <ComboBox
                key={this.state[property + 'PickerKey']}
                titleText={title}
                items={list}
                light={small}
                size={small ? "sm" : undefined}
                itemToString={item => item !== null ? item.value : ""}
                selectedItem={list.filter(item => item.id == this.state[property + 'Id'])[0]}
                onChange={e => {
                    if (e.selectedItem === null) {
                        this.setState({ [property + 'Id']: e.selectedItem !== null ? e.selectedItem.id : 0, [property + 'PickerKey']: Util.newTempId(), })
                    } else {
                        this.setState({ [property + 'Id']: e.selectedItem !== null ? e.selectedItem.id : 0 })
                    }
                }} />
            <div style={{ height: '1rem' }} />
        </>)
    }

    renderFilterIndicator(name, list, property) {
        const value = list.filter(item => item.id == this.state[property + 'Id'])[0];
        return <FilterIndicator name={name} value={value} combobox={this.renderComboBox(undefined, list, property, true)} />
        // const value = list.filter(item => item.id == this.state[property + 'Id'])[0];
        // return (
        //     <div style={{opacity: value ? 1 : 0.65, flex: 1}}>
        //         <h6>{name}</h6>
        //         <p style={{fontSize: 12,}}>{value ? value.value : 'Any'}</p>
        //     </div>
        // )
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>Product Performance</h1>
                    <p style={{ fontSize: 18 }}>Reporting</p>

                    <Section icon={Number_132} title="Select Range">
                        <DateRangePicker
                            onChange={item => this.setState({ dateRange: [item.selection] })}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={this.state.dateRange}
                            direction="horizontal"
                        />
                    </Section>

                    <Section icon={Number_232} title="Select Filters (optional)">
                        {this.renderComboBox("Brand", this.state.brands, 'brand')}
                        {this.renderComboBox("Category", this.state.categories, 'category')}
                        {this.renderComboBox("Subcategory", this.state.subcategories, 'subcategory')}
                        {this.renderComboBox("Department", this.state.departments, 'department')}
                        {this.renderComboBox("Supplier", this.state.suppliers, 'supplier')}
                    </Section>

                    <Section icon={Number_232} title="Report" extraTopMargin={false}>
                        <p>Report Note:</p>
                        <p style={{ fontSize: 12, opacity: 0.65 }}>Product sales that have been returned will be removed</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateReportBtn.bind(this)} disabled={!this.canGenerateReport()} loading={this.state.generatingReport} renderIcon={ReportData16}>Generate Report</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    getCellHeader(cell) {
        for (const header of HEADERS) {
            if (header.key == cell.info.header) {
                return header;
            }
        }
    }

    getCellRow(cell) {
        const id = cell.id.split(":")[0];
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    getRow(id) {
        for (const row of this.state.report.items) {
            if (row.id == id) {
                return row;
            }
        }
    }

    renderCell(key, value, id, row) {
        const cell = { value, id }

        switch (key) {
            case "name":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ProfilePic size={34} src={Api.getThumbnail(OBJECT_TYPE_PRODUCT, row.id)} />
                        <LR to={"/products/" + row.id}>
                            <p style={{ marginLeft: '0.5rem', textOverflow: 'ellipsis', overflow: 'hidden' }}>{cell.value}</p>
                        </LR>
                    </div>
                )

            case "totalProfit":
                return <span style={{ color: 'green' }}>{cell.value}</span>

            case "totalRevenue":
                return <span style={{ color: '#0f62fe' }}>{cell.value}</span>

            case "cogs":
                return <span style={{ color: 'red' }}>{cell.value}</span>

            case "grossProfitMargin":
                return <span style={{ color: '#AF69EF' }}>{cell.value}%</span>

            case "quantitySold":
                return <><DeliveryParcel16 /> {cell.value} {row.uom}</>

            case "initiationDate":
                return <><Calendar16 /> {Util.getDate(cell.value)}</>

            default:
                return cell.value;
        }
    }

    renderReport() {
        return (
            <div className="main-content">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <Link style={{ cursor: 'pointer', }} onClick={this.clearReport.bind(this)}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another report</Link>
                    <div style={{ flex: 1 }} />
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Hover on any filter to change it</p>
                </div>
                {/* <h1>report</h1> */}
                {/* <ProductPriceResult report={this.state.report} /> */}
                {/* <code><pre>{JSON.stringify(this.state.report, null, 4)}</pre></code> */}
                <div style={{ width: '100%', height: 86, padding: '1rem', background: '#f4f4f4', marginBottom: -2, display: 'flex', gap: '1rem' }}>
                    {this.renderFilterIndicator("Brand", this.state.brands, 'brand')}
                    {this.renderFilterIndicator("Category", this.state.categories, 'category')}
                    {this.renderFilterIndicator("Subcategory", this.state.subcategories, 'subcategory')}
                    {this.renderFilterIndicator("Department", this.state.departments, 'department')}
                    {this.renderFilterIndicator("Supplier", this.state.suppliers, 'supplier')}
                </div>

                <PaginatedDataTable
                    reloadRequest={`${this.state.brandId}-
                ${this.state.categoryId}-
                ${this.state.subcategoryId}-
                ${this.state.departmentId}-
                ${this.state.supplierId}`}
                    hasSort hideToolbar
                    loader={this.sendReportRequest.bind(this)}
                    loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                    columns={HEADERS.map(header => ({
                        id: header.key,
                        name: header.header,
                        render: item => this.renderCell(header.key, item[header.key], item.id, item)
                    }))}
                    onClick={() => { }}
                />
            </div>
        )
        // return (
        //     <div className="main-content">
        //         <div onClick={this.clearReport.bind(this)} style={{display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem'}}>
        //             <Link><ArrowLeft16 style={{marginRight: '0.25rem'}} /> Generate another report</Link>    
        //         </div>
        //         <div style={{width: '100%', padding: '1rem', background: '#f4f4f4', marginBottom: -2}}>
        //             {this.state.report !== undefined && this.state.report.values !== undefined &&
        //                 <h4 style={{color: 'green'}}>Total Units Sold: <DeliveryParcel24 /> {this.state.report.values.totalSalesQuantity}</h4>}

        //             <div style={{display: 'flex', alignItems: 'center', opacity: 0.65}}>
        //                 <Calendar16 style={{marginRight: '0.25rem'}} /> <p>{Util.getDateOnly(this.state.startDateValue)}</p>

        //                 <p style={{marginLeft: '0.5rem', marginRight: '0.5rem'}}>-</p>

        //                 <Calendar16 style={{marginRight: '0.25rem'}} /> <p>{Util.getDateOnly(this.state.endDateValue)}</p>
        //             </div>
        //         </div>
        //         {/* <TransactionListView report={this.state.report} hideToolbar /> */}

        //         <DataTable rows={this.state.report.items} headers={HEADERS} isSortable> 
        //             {({
        //                 rows,
        //                 headers,
        //                 getHeaderProps,
        //                 getRowProps,
        //                 getTableProps,
        //                 onInputChange,
        //                 getSelectionProps,
        //                 selectedRows
        //             }) => (
        //                 <TableContainer>
        //                     <Table {...getTableProps()}>
        //                         <TableHead>
        //                             <TableRow>
        //                                 {headers.map((header) => (
        //                                     <TableHeader key={header.key} {...getHeaderProps({ header })}>
        //                                         {header.header}
        //                                     </TableHeader>
        //                                 ))}
        //                             </TableRow>
        //                         </TableHead>
        //                         <TableBody>
        //                             {rows.map((row) => (
        //                                 <TableRow key={row.id} {...getRowProps({ row })}>
        //                                     {row.cells.map((cell) => (
        //                                         <TableCell key={cell.id}>{this.renderCell(cell)}</TableCell>
        //                                     ))}
        //                                 </TableRow>
        //                             ))}
        //                         </TableBody>
        //                     </Table>
        //                     {<TableToolbar>
        //                         {/* <Pagination pageSizes={[10, 20, 30, 40, 50]} /> */}
        //                     </TableToolbar>}
        //                 </TableContainer>
        //             )}
        //         </DataTable>
        //     </div>
        // )
    }

    getLayout() {
        return this.state.generatedReport ? this.renderReport() : this.renderInput()
    }

}

export default ProductSalesReport;